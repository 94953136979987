import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
    Legend,
} from 'recharts';

const PerformanceLogs = () => {
    const [chartData, setChartData] = useState([]);
    const [messagesChartData, setMessagesChartData] = useState([]);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const dateParam = urlParams.get('date');
        const apiUrl = dateParam 
        ? `https://history.funner.co.il/api/services/getPerformanceLogs?date=${dateParam}` 
        : 'https://history.funner.co.il/api/services/getPerformanceLogs';

        const convertToSeconds = (data) => {
            return data.map(item => ({
                ...item,
                min: (item.min / 1000).toFixed(3),
                max: (item.max / 1000).toFixed(3),
                avg: (item.avg / 1000).toFixed(3),
                minNotification: (item.minNotification / 1000).toFixed(3),
                maxNotification: (item.maxNotification / 1000).toFixed(3),
                avgNotification: (item.avgNotification / 1000).toFixed(3),
                minBot: (item.minBot / 1000).toFixed(3),
                maxBot: (item.maxBot / 1000).toFixed(3),
                avgBot: (item.avgBot / 1000).toFixed(3),
            }));
        };

        const fetchData = async () => {
            try {
                const response = await axios.get(apiUrl);
                console.log("response", response);
                const convertedChartData = convertToSeconds(response.data.chartData);
                setChartData(convertedChartData);
                
                setMessagesChartData(response.data.messagesChartData); // Messages count doesn't need conversion
            } catch (error) {
                console.error('Error fetching data: ', error);
                setChartData([]);
                setMessagesChartData([]);
            }
        };

        fetchData();
    }, []);

    return (
        <>
         <div style={{ width: '100%', height: 500 }}>
                <ComposedChart width={1200} height={400} data={messagesChartData}>
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />                   
                    <Line type="monotone" dataKey="messagesCount" stroke="#ff7300" />
                 
                </ComposedChart>
            </div>
           {/*  <div style={{ width: '100%', height: 500 }}>
                <ComposedChart width={1200} height={400} data={chartData}>
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="min" name="Min time to save message" barSize={20} fill="#413ea0" />
                    <Line type="monotone" name="Max time to save message" dataKey="max" stroke="#ff7300" />
                    <Line type="monotone" name="Avg time to save message" dataKey="avg" stroke="#387908" />
                </ComposedChart>
            </div> */}
            <br />
            <div style={{ width: '100%', height: 500 }}>
                <ComposedChart width={1200} height={400} data={chartData}>
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="minNotification" barSize={20} fill="#413ea0" />
                    <Line type="monotone" dataKey="maxNotification" stroke="#ff7300" />
                    <Line type="monotone" dataKey="avgNotification" stroke="#387908" />
                </ComposedChart>
            </div>
            <br />
            <div style={{ width: '100%', height: 500 }}>
                <ComposedChart width={1200} height={400} data={chartData}>
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="minBot" barSize={20} fill="#413ea0" />
                    <Line type="monotone" dataKey="maxBot" stroke="#ff7300" />
                    <Line type="monotone" dataKey="avgBot" stroke="#387908" />
                </ComposedChart>
            </div>
            <div style={{ width: '100%', height: 500 }}>
                <ComposedChart width={1200} height={400} data={messagesChartData}>
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />                   
                    <Line type="monotone" dataKey="messagesCount" stroke="#ff7300" />
                 
                </ComposedChart>
            </div>
        </>
    );
};

export default PerformanceLogs;
