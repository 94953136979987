import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ComposedChart, Line, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from 'recharts';

const ChatUserDuplicatesLog = () => {
    const [messagesChartData, setMessagesChartData] = useState([]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        // localhost:3000/page-performance-log?date=2024-10-7
        const dateParam = urlParams.get('date');
        const apiUrl = dateParam
            ? `https://history.funner.co.il/api/services/duplicatesLog?date=${dateParam}`
            : 'https://history.funner.co.il/api/services/getPerformanceLogs';

        const fetchData = async () => {
            try {
                const response = await axios.get(apiUrl);
                const groupedLogs = response.data.groupedLogs;

                const chartData = groupedLogs.map(log => ({
                    name: log.CompanyName,
                    succeededCount: log.SucceededCount,
                    failedCount: log.FailedCount,
                }));
                
                setMessagesChartData(chartData);
            } catch (error) {
                console.error("Failed to fetch data", error);
            }
        };

        fetchData();
    }, []); // This effect runs only once after the initial render
   
    return (
        <>
        <h1>Duplicates Log</h1>
        <div style={{ width: '100%', height: 500 }}>
            <ComposedChart width={1200} height={400} data={messagesChartData}>
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="succeededCount" stroke="#28a745" name="Duplicates"/>
                <Line type="monotone" dataKey="failedCount" stroke="#085c7d" name="Script Runs Today"/>
            </ComposedChart>
        </div>
        </>
    );
};

export default ChatUserDuplicatesLog;
