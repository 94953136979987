import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
    Legend,
} from 'recharts';

const QueueLogs = () => {
    const [chartData, setChartData] = useState([]);
    const [messagesChartData, setMessagesChartData] = useState([]);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const dateParam = urlParams.get('date');
        const apiUrl = `https://app.funner.co.il/api/make/GetPerformanceTemplatesQueueLogs/?date=${dateParam}`;

        const convertToSeconds = (data) => {
            return data.map(item => ({
                ...item,
                min: (item.min / 1000).toFixed(3),
                max: (item.max / 1000).toFixed(3),
                avg: (item.avg / 1000).toFixed(3),
                minNotification: (item.minNotification / 1000).toFixed(3),
                maxNotification: (item.maxNotification / 1000).toFixed(3),
                avgNotification: (item.avgNotification / 1000).toFixed(3),
                minBot: (item.minBot / 1000).toFixed(3),
                maxBot: (item.maxBot / 1000).toFixed(3),
                avgBot: (item.avgBot / 1000).toFixed(3),
            }));
        };

        const fetchData = async () => {
            try {
                const response = await axios.get(apiUrl);
                console.log("response", response);


                setChartData(response.data.GroupedLogs);


            } catch (error) {
                console.error('Error fetching data: ', error);
                setChartData([]);
                setMessagesChartData([]);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <h1>Queue Performance</h1>
            <div style={{ width: '100%', height: 500 }}>
                <ComposedChart width={1200} height={400} data={chartData}>
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="TimeSlot" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="MinTimeToSend" name="Min to Send" barSize={20} fill="#413ea0" />
                    <Line type="monotone" name="Max to Send" dataKey="MaxTimeToSend" stroke="#ff7300" />
                    <Line type="monotone" name="Avg to Send" dataKey="AverageTimeToSend" stroke="#387908" />

                    <Line type="monotone" name="Messages Number" dataKey="Logs.length" stroke="red" />


                </ComposedChart>
            </div>
        </>
    );
};

export default QueueLogs;
